import { apiv1 } from "@/request/apiv1";
import qs from "qs";
import axios from "axios";

// 获取所有的答案列表
export function GetAnswersAll() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/answers/all`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


// 获取答案的分页列表
export function GetAnswersList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/answers/page?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 根据id获取具体的答案数据
export function GetAnswersById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/answers/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


// 修改指定答案的数据
export function PutAnswersById(id = "", params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/v1/clients/answers/${id}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 新建答案
export function PostAnswers(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.post(`/v1/clients/answers`, params)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 动态设置星标
 * @param {String,Number} id 
 * @param {Boolean} bool 
 */
export function ToggleStartAnswer(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.put(`/v1/clients/answers/${id}/star`)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 通过id删除指定的答案
// export function DeleteAnswersById(id = "") {
//     return new Promise((resolve, reject) => {
//         apiv1.delete(`/v1/clients/answers/${id}`)
//             .then(res => {
//                 if (res.status === 204) {
//                     resolve(res)
//                 }
//             })
//             .catch(err => {
//                 reject(err)
//             });
//     })
// }

/**
 * 动态批量设置启用\禁用
 * @param {Arrary} multipleSelectieIds 
 * @param {Boolean} bool 
 */
 export function ToggleMultipleDisable(multipleSelectieIds = [], bool = false) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        multipleSelectieIds.forEach(id => {
            multipleRequests.push(apiv1.put(`/v1/clients/answers/${id}/disable/${bool}`))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取启用或者禁用许可
 * @param {String,Number} id 
 */
 export function GetDiasbleCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/answers/${id}/can/disable`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取修改许可
 * @param {String,Number} id 
 */
 export function GetUpdateCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/answers/${id}/can/update`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取星标设置许可
 * @param {String,Number} id 
 */
 export function GetStarCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/answers/${id}/can/star`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}