<template>
  <el-form
    :model="form"
    :rules="rules"
    status-icon
    ref="companyForm"
    label-width="100px"
    class="company-ruleForm"
    :disabled="submitting"
    v-loading="loading"
  >
    <el-form-item label="类型：" prop="typeId">
      <BasicSelect
        v-model="form.typeId"
        :allOptions="typeOptions"
      />
    </el-form-item>
    <el-form-item label="问题：" prop="content">
      <el-input
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 4 }"
        placeholder="请输入内容"
        v-model="form.content"
      >
      </el-input>
    </el-form-item>
    <el-form-item label="上传文件：" prop="fileIds">
      <el-button type="primary" size="small" @click="showFileDialog = true">{{
        fileList.length > 0 ? `已选${fileList.length}个文件` : "选择文件"
      }}</el-button>
      <div class="file-content-list" v-if="fileList.length > 0">
        <template v-for="(item, index) in fileList">
          <FilesItem
            showDelete
            :showCheckBox="false"
            :item="item"
            @onDelete="onFileDelete(index)"
            :key="item.id"
            class="mt-r"
          />
        </template>
      </div>
    </el-form-item>

    <el-form-item style="text-align: right">
      <el-button @click="onCancel" :disabled="submitting" :loading="loading"
        >关 闭</el-button
      >
      <el-button
        v-if="isEmprty(id)"
        type="primary"
        :loading="submitting"
        @click="submitForm('companyForm')"
        >立即创建</el-button
      >
      <el-button
        type="warning"
        :loading="submitting"
        @click="editForm('companyForm')"
        v-if="!isEmprty(id)"
        >立即修改</el-button
      >
    </el-form-item>
    <el-dialog
      title="选择文件"
      :visible.sync="showFileDialog"
      append-to-body
      :close-on-click-modal="false"
      width="80%"
    >
      <FilesView
        ref="FilesView"
        :fileItems="fileList"
        @confirm="onFileConfirm"
        @cancel="onFileCancel"
      />
    </el-dialog>
  </el-form>
</template>

<script>
import {
  PostQuestions,
  PutQuestionsById,
  GetQuestionsById,
} from "../api/question";
import { GetQuestionsTypeAll } from "@/views/questions/questionsTypes/api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import FilesView from "@/views/filesModule/FilesView";
import FilesItem from "@/views/filesModule/components/FilesItem";
import BasicSelect from "@/components/BasicSelect";
export default {
  props: {
    id: {
      type: [Number, String],
      default: "",
    },
  },
  components: {
    FilesView,
    FilesItem,
    BasicSelect,
  },
  data() {
    return {
      loading: false,
      submitting: false,
      showFileDialog: false,
      fileList: [],
      form: {
        typeId: null,
        content: "",
        fileIds: [],
      },
      typeOptions: [],
      rules: {
        content: [{ required: true, message: "请输入问题", trigger: "blur" }],
      },
    };
  },
  watch: {
    id: {
      handler() {
        this.getTypeOptions();
        if (!isEmprty(this.id)) {
          this.getFormData(this.id);
        } else {
          this.inintForm();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    isEmprty,
    checkPhoneNumber(rule, value, callback) {
      // console.log('rule', rule)
      if (!value) {
        return callback(new Error("手机号码不能为空"));
      }
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error("电话号码格式不正确"));
      }
    },
    getTypeOptions() {
      GetQuestionsTypeAll().then((res) => {
        this.typeOptions = res.data;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PostQuestions({
            typeId: this.form.typeId,
            content: this.form.content,
            fileIds: this.fileList.map((item) => item.id),
          })
            .then(() => {
              this.submitting = false;
              this.inintForm();
              this.onEmitSuccess();
              this.$message.success("新建成功");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("新增失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },

    editForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutQuestionsById(this.id, {
            typeId: this.form.typeId,
            content: this.form.content,
            fileIds: this.fileList.map((item) => item.id),
          })
            .then(() => {
              this.submitting = false;
              this.onEmitSuccess();
              this.inintForm();
              this.$message.success("修改成功");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("修改失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    getFormData(id = "") {
      this.loading = true;
      GetQuestionsById(id)
        .then((res) => {
          let { files, content, typeId } = res.data;
          this.fileList = files;
          this.form = {
            typeId: typeId,
            content: content,
            fileIds: files.map((item) => item.id),
          };

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取表单", err);
        });
    },
    onCancel() {
      this.$emit("cancel");
    },
    onEmitSuccess() {
      this.$emit("success");
    },
    inintForm() {
      this.form = {
        typeId: null,
        content: "",
        fileIds: [],
      };
      this.fileList = [];
      this.$nextTick(() => {
        this.$refs["companyForm"].resetFields();
      });
    },
    onFileConfirm({ items }) {
      this.fileList = items;
      this.showFileDialog = false;
    },
    onFileCancel() {
      this.showFileDialog = false;
    },
    onFileDelete(index) {
      this.fileList.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-content-list {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 15px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
</style>